<template>
  <div
    class="card mb-5"
    :class="isLoading?'sec-saving':''"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            General information
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="isEditButtonDisabled"
          :is-loading="isLoading"
          @changeMode="changeModeProxy"
          @update="saveChanges"
        />
      </div>
    </div>
    <div class="card-body">
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
        :default-value="legalSignerByIdInfo"
      >
        <div class="mb-4 bg-light full-width">
          <div class="col-md-4">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td>Key Party Type:</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      v-model="model.signerType"
                      disabled
                      :options="legalSignerOptionsList.signerType"
                      name="investorType"
                      @input="callConfirmModal"
                    />
                    <strong v-else>
                      {{ legalSignerByIdInfo.signerType }}
                    </strong>
                  </td>
                </tr><!--investorType-->
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <template v-if="isEntity">
                  <tr>
                    <td>Name:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.legalName"
                        name="legalName"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.legalName }}
                      </strong>
                    </td>
                  </tr><!--Name-->
                  <tr>
                    <td>Email:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.email"
                        name="email"
                        validate="required|email"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.email }}
                      </strong>
                    </td>
                  </tr><!--Email-->
                  <tr>
                    <td>DBA:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.businessName"
                        name="businessName"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.businessName }}
                      </strong>
                    </td>
                  </tr><!--entityDba-->
                </template>
                <template v-if="isIndividual">
                  <tr>
                    <td>First Name:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.individualName.firstName"
                        name="firstName"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.individualName.firstName }}
                      </strong>
                    </td>
                  </tr><!--firstName-->
                  <tr>
                    <td>Middle Name:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"

                        v-model="model.individualName.middleName"
                        name="middleName"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.individualName.middleName }}
                      </strong>
                    </td>
                  </tr><!--middleName-->
                  <tr>
                    <td>Last Name:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.individualName.lastName"
                        name="lastName"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.individualName.lastName }}
                      </strong>
                    </td>
                  </tr><!--lastName-->
                  <tr>
                    <td>Email:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.email"
                        name="email"
                        validate="required|email"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.email }}
                      </strong>
                    </td>
                  </tr><!--email-->
                  <tr>
                    <td>Birthdate</td>
                    <td>
                      <cp-date-picker
                        v-if="isDetailsEditableCurrentBlock"
                        name="birthDate"
                        initial-view="year"
                        :open-date="openDate"
                        :disabled-dates="disabledDates"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.birthDate | dateFilter }}
                      </strong>
                    </td>
                  </tr><!--birthDate-->
                  <tr>
                    <td>
                      Country of Birth:
                    </td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        key="birthCountryCode"
                        v-model="model.address.birthCountryCode"
                        :options="countries"
                        name="birthCountryCode"
                      />
                      <strong v-else>
                        {{ getCurrentCountryName('birthCountryCode') | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--birthCountryCode-->
                  <tr v-if="model.address.birthCountryCode === 'US'">
                    <td>
                      State of Birth:
                    </td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.birthState"
                        :options="stateOptions"
                        name="birthState"
                        validate="required"
                      />
                      <strong v-else>
                        {{ generalDetailState('birthState') | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--birthState-->
                  <tr>
                    <td>City of Birth:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.birthCity"
                        name="birthCity"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.address.birthCity | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--birthCity-->
                </template>
                <tr>
                  <td>TaxID:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      v-model="model.taxId"
                      name="taxId1"

                      validate="required"
                    />
                    <strong v-else>
                      {{ legalSignerByIdInfo.taxId | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId-->
                <tr>
                  <td>Tax Country:</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxCountryCode"
                      v-model="model.taxCountryCode"
                      :options="countries"
                      name="taxCountryCode"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('taxCountryCode', true) | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode-->
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr>
                  <td>Legal Signer</td>
                  <td>
                    <strong
                      v-if="!isDetailsEditableCurrentBlock"
                      class="text-capitalize"
                    >
                      {{ getIsLegalSigner() }}
                    </strong>
                    <b-form-group v-else>
                      <b-form-radio
                        v-for="({ text, value }, index) in legalSignerOptionsList.isLegalSignerList"
                        :key="index"
                        v-model="model.isLegalSigner"
                        :value="value"
                        class="text-capitalize"
                        inline
                        plain
                      >
                        <strong>{{ text }}</strong>
                      </b-form-radio>
                    </b-form-group>
                  </td>
                </tr><!--isLegalSigner-->
                <tr>
                  <td>Beneficial owner</td>
                  <td>
                    <strong
                      v-if="!isDetailsEditableCurrentBlock"
                      class="text-capitalize"
                    >
                      {{ getUboOwning(model.uboOwning) }}
                    </strong>
                    <b-form-group v-else>
                      <b-form-radio
                        v-for="({ text, value }, index) in legalSignerOptionsList.uboOptionsList"
                        :key="index"
                        v-model="model.uboOwning"
                        :value="value"
                        class="text-capitalize"
                        inline
                        plain
                        @change="handleUBOChange"
                      >
                        <strong>{{ text }}</strong>
                      </b-form-radio>
                    </b-form-group>
                  </td>
                </tr><!--beneficialOwner-->
                <template v-if="isEntity">
                  <tr>
                    <td>Type:</td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.entityType"

                        :options="legalSignerOptionsList.entityTypesList"
                        name="entityType"
                        validate="required"
                      />
                      <strong v-else>
                        {{ $t(`securitizeId.entityType["${legalSignerByIdInfo.entityType}"]`) || legalSignerByIdInfo.entityType }}
                      </strong>
                    </td>
                  </tr><!--entityType-->
                </template>
                <template v-if="isIndividual">
                  <tr>
                    <td>
                      Country:
                    </td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        key="countryCode"
                        v-model="model.address.countryCode"
                        :options="countries"
                        name="countryCode"
                        validate="required"
                      />
                      <strong v-else>
                        {{ getCurrentCountryName('countryCode') | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--countryCode-->
                  <tr v-if="model.address.countryCode === 'US'">
                    <td>
                      State:
                    </td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.state"
                        :options="stateOptions"
                        name="state"
                        validate="required"
                      />
                      <strong v-else>
                        {{ generalDetailState('state') | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--state-->
                  <tr>
                    <td>City:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.city"
                        name="city"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.address.city | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--city-->
                  <tr>
                    <td>Postal Code:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.zip"
                        name="postalCode"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.address.zip | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--postalCode-->
                  <tr>
                    <td>Street Name:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.street"

                        name="streetName"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.address.street }}
                      </strong>
                    </td>
                  </tr><!--streetName-->
                  <tr>
                    <td>Street Number:</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.houseNumber"
                        name="streetNumber"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerByIdInfo.address.houseNumber }}
                      </strong>
                    </td>
                  </tr><!--streetNumber-->
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </cp-input-container>
    </div>
    <cp-confirm-modal
      ref="cpConfirmModal"
      title="Change investor type"
      ok-only
      @onOk="$emit('confirm')"
    >
      Note that when changing the investor type, some of the information might be lost
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import states from '@/utilities/us-states-list';
import { countriesList as countries, legalSignerOptionsList } from '~/pages/securitize-id/components/options';
import { CpDatePicker, CpSelect, CpInput } from '@/components/common/standalone-components/inputs';
import cpInputContainer from '@/components/common/cpInputContainer';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import CpEditButtons from '~/components/common/edit-buttons';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'SecuritizeIdLegalSignerGeneralInformation',
  components: {
    cpInputContainer,
    CpDatePicker,
    CpSelect,
    CpInput,
    CpConfirmModal,
    CpEditButtons,
  },
  mixins: [
    CpEditableMixin('general', 'securitizeId'),
  ],
  data() {
    return {
      isLoading: false,
      states,
      countries,
      legalSignerOptionsList,
      investorId: this.$route.params.investorId,
      legalSignerId: this.$route.params.legalSignerId,
      disabledDates: {
        from: new Date(moment()
          .subtract(15, 'years')),
      },
      openDate: new Date('1990-01-01'),
      model: {},
    };
  },
  computed: {
    ...mapState('securitizeId', ['legalSignerByIdInfo']),
    stateOptions() {
      return Object.keys(this.states)
        .map(val => ({ text: this.states[val], value: val }));
    },
    isIndividual() {
      return this.model.signerType === 'individual';
    },
    isEntity() {
      return this.model.signerType === 'entity';
    },
  },
  async created() {
    this.changeMode(true);
    this.model = JSON.parse(JSON.stringify(this.legalSignerByIdInfo));
  },
  methods: {
    ...mapActions('securitizeId', ['updateSecuritizeIdLegalSignerById']),
    changeModeProxy() {
      this.model = JSON.parse(JSON.stringify(this.legalSignerByIdInfo));
      this.changeMode();
    },
    getCurrentCountryName(field, isTax) {
      const country = this.countries.find((item) => {
        const fieldToMatch = isTax ? this.legalSignerByIdInfo[field] : this.legalSignerByIdInfo.address[field];
        return item.value === fieldToMatch;
      });
      return country ? country.text : null;
    },
    generalDetailState(field) {
      const currentState = this.stateOptions.find(el => el.value === this.model.address[field]);
      return currentState ? currentState.text : null;
    },
    saveChanges() {
      if (this.model.address?.countryCode && this.model.address?.countryCode !== 'US') {
        this.model.address.state = undefined;
      }
      if (this.model.address?.birthCountryCode && this.model.address?.birthCountryCode !== 'US') {
        this.model.address.birthState = undefined;
      }
      this.$refs.cpInputContainer.validateForm()
        .then(() => {
          if (this.$refs.cpInputContainer.isValid()) {
            this.isLoading = true;
            this.updateSecuritizeIdLegalSignerById({
              investorId: this.investorId,
              legalSignerId: this.legalSignerId,
              legalSigner: this.model,
            })
              .then(() => {
                this.isLoading = false;
                this.changeModeProxy();
              });
          }
        });
    },
    callConfirmModal() {
      this.$refs.cpConfirmModal.show();
    },
    handleUBOChange(e) {
      this.model.isBeneficialOwner = e !== 'none';
    },
    getUboOwning(uboOwning) {
      return (this.legalSignerOptionsList.uboOptionsList.find(v => v.value === uboOwning) || {}).text || 'No';
    },
    getIsLegalSigner() {
      return (this.legalSignerOptionsList.isLegalSignerList.find(v => v.value === this.legalSignerByIdInfo.isLegalSigner) || {}).text || 'N/A';
    },
  },
};
</script>
<style scoped>
    .full-width {
        margin: -24px;
    }
    .sec-saving::after{
      content:"";
      position: absolute;
      width:100%;
      height:100%;
      background: #fff;
      opacity: 0.4;
    }
</style>
