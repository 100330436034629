<template>
  <b-modal
    ref="uploadDocument"
    :title="$t('investorDetails.addDocumentModal.title')"
    @show="clearModal"
    @ok="sendDocument"
  >
    <cp-file-uploader
      id="investor-document-uploader"
      :document-error="documentError"
      :data-check="checkDockSize"
      :file-rules="checkDockSize"
      :max-file-size="maxDocSize"
      :use-secure="true"
      class-style-override="small-footprint"
      show-default-message
      @onChange="fileUploaded"
      @fileLoading="fileLoading"
    />
    <hr>
    <cp-select
      v-model="documentTypeModel"
      :label="$t('investorDetails.addDocumentModal.input.category')"
      :options="documentTypes"
    />
    <!-- <cp-select
      v-model="documentData.face"
      :label="$t('investorDetails.addDocumentModal.input.documentFace')"
      :options="categories.face"
    /> -->

    <template #modal-footer="{ cancel, ok }">
      <b-button
        @click="cancel"
      >
        Cancel
      </b-button>
      <cp-button
        :disabled="createProcess"
        size="md"

        variant="primary"
        :is-loading="saving"
        @click="ok"
      >
        Add Document
      </cp-button>
    </template>
  </b-modal>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';

export default {
  name: 'AddDocumentModal',
  components: {
    CpSelect,
    CpFileUploader,
    CpButton,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      newFile: {},
      maxDocSize: 50000000, // max upload file size 50mb
      fileKey: '',
      entityDocumentData: {
        side: 'front',
      },
      documentTypeModel: null,
      individualDocumentData: {
        side: 'front',
        docType: 'passport',
        docCategory: 'signer-identification',
      },
      documentError: false,
      createProcess: false,
      investorId: this.$route.params.investorId,
      legalSignerId: this.$route.params.legalSignerId,
    };
  },
  computed: {
    ...mapState('securitizeId', ['legalSignerByIdInfo']),
    documentTypes() {
      return this.legalSignerByIdInfo.signerType === 'entity'
        ? this.categories.entityDocumentTypes
        : this.categories.documentTypeIndividualFull;
    },
  },
  methods: {
    ...mapActions('securitizeId', ['addSecuritizeIdLegalSignerDocument']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    checkDockSize({ size }) {
      return size > this.maxDocSize;
    },
    fileUploaded({ file, fileKey }) {
      if (!fileKey) this.onUploadError(file);
      this.documentError = false;
      this.newFile = file;
      this.fileKey = fileKey;
      this.createProcess = false;
    },
    onUploadError(file) {
      let errorText = 'Upload Error';
      if (file && file.status === 'error') {
        if (file.xhr && file.xhr.responseText) {
          errorText = file.xhr.responseText;
        }
      }
      this.showErrorMessage(errorText);
    },
    showErrorMessage(error) {
      this.CALL_ERROR_TOASTER(error);
      this.$log.error('Legal signer add document upload file error:', error);
    },
    fileLoading() {
      this.createProcess = true;
    },
    clearModal() {
      this.newFile = {};
      this.documentError = false;
    },
    sendDocument(env) {
      env.preventDefault();
      if (!this.newFile.accepted) {
        this.documentError = true;
        return;
      }
      if (!this.newFile) {
        this.CALL_ERROR_TOASTER('Please upload the document');
        return;
      } if (!this.newFile.accepted) {
        this.CALL_ERROR_TOASTER('File must be between 20KB and 50MB');
        return;
      }
      if (this.createProcess) {
        return;
      }
      this.saving = true;
      this.createProcess = true;
      if (this.legalSignerByIdInfo.signerType === 'entity') this.document = { ...this.document, ...this.entityDocumentData };
      if (this.legalSignerByIdInfo.signerType === 'individual') this.document = { ...this.document, ...this.individualDocumentData };
      this.document = {
        ...this.document,
        ...this.documentTypeModel,
      };
      this.addSecuritizeIdLegalSignerDocument({
        investorId: this.investorId,
        legalSignerId: this.legalSignerId,
        document: {
          fileKey: this.fileKey,
          fileType: this.newFile.type,
          ...this.document,
        },
      }).then(() => {
        this.$refs.uploadDocument.hide();
        this.$emit('docIsAdded');
      }).finally(() => {
        this.createProcess = false;
        this.saving = false;
      });
    },
  },
};
</script>
