var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5",class:_vm.isLoading?'sec-saving':''},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" Documents ")]),_c('cp-edit-buttons',{attrs:{"edit-mode":_vm.editMode},on:{"changeMode":_vm.changeModeProxy,"update":_vm.saveChanges}},[_c('template',{slot:"leftToButtons"},[_c('cp-button',{staticClass:"mr-3",attrs:{"icon":"ios-add","variant":"primary"},on:{"click":_vm.showAddDocModal}},[_vm._v(" Add document ")])],1)],2)],1)])]),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"card-table",attrs:{"items":_vm.legalSignerByIdInfo.documents,"url-params":_vm.queryParams,"fields":_vm.documentsFields},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"ui-w-40 d-block link",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getImageIcon(item),"alt":"Document image"},on:{"click":function($event){return _vm.showPreviewDocModal(item)}}})]}},{key:"docType",fn:function(rowData){return [(!_vm.editMode)?_c('span',[_vm._v(" "+_vm._s(_vm.getDocType(rowData.item.docType))+" ")]):_c('cp-select',{attrs:{"options":_vm.getDocumentTypesList()},model:{value:(_vm.model[rowData.index].docType),callback:function ($$v) {_vm.$set(_vm.model[rowData.index], "docType", $$v)},expression:"model[rowData.index].docType"}})]}},{key:"status",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.editMode)?_c('b-badge',{class:_vm.getStatusColor(item)},[_vm._v(" "+_vm._s(item.verificationStatus)+" ")]):_c('cp-select',{attrs:{"options":_vm.statusOptions},model:{value:(_vm.model[index].verificationStatus),callback:function ($$v) {_vm.$set(_vm.model[index], "verificationStatus", $$v)},expression:"model[index].verificationStatus"}})]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.createDate))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"icon":"ios-arrow-round-down","variant":"outline-primary","disabled":_vm.downloadingDocuments[item.documentId],"is-loading":_vm.downloadingDocuments[item.documentId]},on:{"click":function($event){return _vm.downloadDocument(item)}}},[_vm._v(" Download ")]),_c('cp-button',{attrs:{"icon":"ion ion-ios-trash","variant":"default ml-3","disabled":_vm.documetsCount < 2},on:{"click":function($event){return _vm.showDeleteModal(item)}}},[(_vm.documetsCount < 2)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],attrs:{"title":(_vm.documetsCount < 2) ?'At least one document must be associated':''}},[_vm._v(" Delete ")]):_vm._e(),(_vm.documetsCount > 1)?_c('span',[_vm._v(" Delete ")]):_vm._e()])],1)]}}])})],1),_c('add-document-modal',{ref:"addDocModal",attrs:{"categories":_vm.categories},on:{"docIsAdded":_vm.refreshTable}}),_c('preview-document-modal',{ref:"previewDocModal",attrs:{"document-id":_vm.documentId,"investor-id":_vm.queryParams.investorId,"file-type":_vm.fileType}}),_c('cp-confirm-modal',{ref:"cpConfirmModal",attrs:{"title":_vm.$t('investorDetails.removeModal.title', [_vm.$t('investorDetails.documents.label.document')])},on:{"onOk":_vm.deleteDocument}},[_vm._v(" "+_vm._s(_vm.confirmModalText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }