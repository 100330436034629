<template>
  <b-modal
    ref="previewDocument"
    size="lg"
    hide-footer
    ok-only
    centered
    modal-class="cp-preview-modal"
    :title="$t('investorDetails.previewDocumentModal.title')"
    @shown="previewDocument"
    @ok="closePreview"
  >
    <img
      v-if="previewImage"
      class="d-inline-block"
      :src="previewImage"
      :alt="$t('investorDetails.previewDocumentModal.label.imageAlt')"
    >
    <iframe
      v-if="previewHtml"
      id="preview-kyc-iframe"
      :src="previewHtml"
      frameborder="0"
    />
    <Spinner
      v-if="!previewImage && !previewHtml"
      name="wave"
      color="#04bec4"
      class="cp-button-spinner"
      fade-in="quarter"
    />
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from 'vue-spinkit';

export default {
  name: 'PreviewDocumentModal',
  components: {
    Spinner,
  },
  props: {
    documentId: {
      type: String,
      default: '',
    },
    fileType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      previewImage: '',
      previewHtml: '',
    };
  },
  methods: {
    ...mapActions('securitizeId', ['getSecuritizeILegalSignerDocumentById']),
    closePreview() {
      this.$refs.previewDocument.hide();
    },
    previewDocument() {
      this.previewImage = '';
      this.previewHtml = '';
      this.getSecuritizeILegalSignerDocumentById({
        documentId: this.documentId,
        legalSignerId: this.$route.params.legalSignerId,
        investorId: this.$route.params.investorId,
      }).then(({ url }) => {
        if (this.fileType !== 'application/pdf') {
          this.previewImage = url;
        } else {
          this.previewHtml = url;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .cp-preview-modal{
    text-align: center;
    img{
      max-width:100%;
    }
    iframe{
      border:0;
      width:100%;
      height:700px;
    }
  }
  .cp-button-spinner{
    width: 100%;
    height: 20px;
    position: absolute;
    margin: auto;
    background: rgba(255,255,255,1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.6;
  }
  img.link{
    cursor: pointer;
  }
</style>
