<template>
  <div v-if="isReady">
    <div class="row align-items-center mb-4">
      <div class="col-md-8">
        <b-button
          class="btn btn-outline-primary btn-sm"
          @click="goToList"
        >
          <i class="ion ion-ios-arrow-back" />
          Back to Investor Details
        </b-button>
      </div>
      <div class="col-md-4 d-md-flex justify-content-end">
        <span class="text-muted" />
      </div>
    </div>

    <div class="row align-items-end mb-2 row-overwrite justify-content-between">
      <div class="py-3">
        <h4 class="font-weight-bold">
          Edit Key Party
        </h4>
      </div>
    </div>
    <securitize-id-legal-signer-general-information />
    <legal-signer-documents />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SecuritizeIdLegalSignerGeneralInformation from './components/details/legal-signer/securitizeid-legal-signer-general-information';
import LegalSignerDocuments from './components/details/legal-signer/documents';
import routeNames from '~/utilities/routes';

export default {
  name: 'SecuritizeIdInvestor',
  metaInfo: {
    title: 'Edit Legal Signer',
  },
  components: {
    SecuritizeIdLegalSignerGeneralInformation,
    LegalSignerDocuments,
  },
  mixins: [],
  props: {
    linkToList: {
      type: String,
      default: '/securitize-id', // replace with "required: true" when list-page is ready
    },
  },
  data() {
    return {
      investorId: this.$route.params.investorId,
      legalSignerId: this.$route.params.legalSignerId,
      isReady: false,
    };
  },
  computed: {
    ...mapState('securitizeId', ['legalSignerByIdInfo']),
  },
  async created() {
    await this.getSecuritizeIdLegalSignerById({ params: { investorId: this.investorId, legalSignerId: this.legalSignerId } });
    this.isReady = true;
  },
  methods: {
    ...mapActions('securitizeId', ['getSecuritizeIdLegalSignerById']),

    goToList() {
      this.$router.push(routeNames.securitizeIdInvestor(this.investorId));
    },
  },

};
</script>
